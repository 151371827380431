<!--
 * @Author: your name
 * @Date: 2021-01-11 10:48:20
 * @LastEditTime: 2021-01-11 18:43:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\mustread\index.vue
-->
<template>
  <div class="allclass">
    <navigation :msg="msg"></navigation>
    <div class="themsgclass">
      <div class="topmsgclass">
        <span>必读！30秒</span>掌握二维码使用管理方法
      </div>
      <div class="im1">
        <img src="../../assets/fangfa.png" />
      </div>
      <div class="qrmsg">“二维码授权”背调有多省事<span>?</span></div>
      <div class="qrmsg2">
        <p>
          <a class="qrmm"></a
          >二维码授权指生成一个授权二维码，多个候选人、随时随地可以通过扫码完成背调流程。
        </p>
        <div>
          <a class="qrmm"></a
          >此模式适用于批量招聘和背调同一类型岗位<span>（例如多门店保姆、月嫂、中介职介所，工厂等）</span>，二维码授权“一对多”创新模式大大解放了委托方的时间，提高了背调效率。
        </div>
      </div>
      <div class="tipclass">
        <div class="readclass1"><img src="../../assets/read1.png" /></div>
        <div class="qrname">二维码名称</div>
      </div>
      <div class="shunxu">
        每次发起二维码授权时填写的名称，会按时间先后顺序排列
      </div>
      <div class="read2class">
        <img src="../../assets/read2.png" />
      </div>
      <div class="tipclass">
        <div class="seeclass">
          <img src="../../assets/see.png" />
        </div>
        <div class="yulanqr">“预览二维码”</div>
      </div>
      <div class="qrs">
        点击可展示已生成二维码，发送至候选人；<br />
        支持<span>反复点击、反复展示二维码 </span>
      </div>
      <div class="read3class">
        <img src="../../assets/read3.png" />
      </div>
      <div class="tipclass">
        <div class="bookclass"><img src="../../assets/book.png" /></div>
        <div class="ztclass">二维码状态</div>
      </div>
      <div class="openclass">
        <div>
          状态为<span>“开启”</span>，代表此二维码<span>可用</span>，候选人可以扫码进行背调流程；
        </div>
        <div>
          状态为<span>“关闭”</span>，代表此二维码<span>不可用</span>，此时扫码则无效；
        </div>
        <div>委托人可通过点击<span>“设置”，修改二维码开/关状态；</span></div>
      </div>
      <div class="read4class">
        <img src="../../assets/read4.png" />
      </div>
      <div class="thetimsgclass">
        <div>
          <div class="ticlass">
            <div></div>
            <div>贴别贴士</div>
          </div>
          <div class="ticlass2">
            <div>
              如选择委托方支付，二维码不被使用的时候请及时调至关闭状态，以防被恶意反复扫码
            </div>
          </div>
        </div>
        <div class="read5class">
          <img src="../../assets/read5.png" />
        </div>
      </div>
      <div class="read6class">
        <div><img src="../../assets/read6.png" /></div>
        <div>“删除”功能</div>
      </div>
      <div class="themsg1">
        <div>勾选不用的二维码，点击删除。</div>
        <div>
          二维码<span>被删除</span>则默认此二维码背调功能<span>永久关闭</span>，<span>请谨慎删除！</span>（已生成报告继续存在“背调记录”）
        </div>
        <div></div>
      </div>
      <div class="read7class">
        <img src="../../assets/read7.png" />
      </div>
    </div>
    <div class="noclass"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: "必读",
    };
  },
};
</script>
<style scoped>
.noclass{
    height: 1rem;
}
.tipclass {
  display: flex;
  align-items: center;
  margin-bottom: 0.45rem;
}
.tipclass > div:first-child {
  margin-right: 0.45rem;
}
.read7class {
  text-align: center;
  margin-top: 0.8rem;
}
.read7class img {
  width: 4.74rem;
}
.themsg1 {
  font-size: 0.3rem;
  line-height: 0.45rem;
}
.themsg1 span {
  font-weight: bold;
}
.read6class > div:nth-child(2) {
  font-size: 0.45rem;
  line-height: 0.63rem;
  font-weight: bold;
  margin-left: 0.45rem;
}
.read6class img {
  width: 0.86rem;
  height: 0.86rem;
}
.read6class {
  margin-top: 0.87rem;
  margin-bottom: 0.48rem;
  display: flex;
  align-items: center;
}
.read5class {
  text-align: center;
  min-width: 3rem;
  text-align: center;
}
.read5class img {
  width: 1.74rem;
}
.thetimsgclass {
  display: flex;
}
.ticlass2 {
  font-size: 0.3rem;
  line-height: 0.42rem;
  margin-top: 0.15rem;
}
.ticlass {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  line-height: 0.42rem;
  font-weight: bold;
}
.ticlass > div:first-child {
  width: 0.09rem;
  height: 0.27rem;
  background-color: #f55613;
  margin-right: 0.2rem;
}
.read4class {
  text-align: center;
  margin: 0.93rem 0;
}
.read4class img {
  width: 3.52rem;
}
.openclass span {
  font-weight: bold;
}
.openclass {
  font-size: 0.3rem;
  line-height: 0.45rem;
}
.ztclass {
  font-size: 0.45rem;
  line-height: 0.63rem;
  font-weight: bold;
}
.bookclass img {
  width: 0.86rem;
}

.read3class img {
  width: 4.45rem;
}
.read3class {
  text-align: center;
  margin-bottom: 0.86rem;
}
.qrs span {
  font-weight: bold;
}
.qrs {
  font-size: 0.3rem;
  line-height: 0.45rem;
  margin-bottom: 0.41rem;
}
.yulanqr {
  font-size: 0.45rem;
  line-height: 0.63rem;
  font-weight: bold;
}
.seeclass img {
  width: 0.86rem;
  height: 0.86rem;
}
.read2class {
  text-align: center;
  margin-top: 0.65rem;
  margin-bottom: 0.79rem;
}
.read2class img {
  width: 5.34rem;
}
.shunxu {
  font-size: 0.3rem;
  line-height: 0.45rem;
}
.themsgclass {
  padding: 0 0.3rem;
}
.qrname {
  font-size: 0.45rem;
  line-height: 0.63rem;
  font-weight: bold;
}
.readclass1 img {
  width: 0.86rem;
  height: 0.86rem;
}
.qrmm::before {
  content: "\00A0\00A0\00A0\00A0\00A0";
  display: inline;
}
.qrmsg2 {
  font-size: 0.32rem;
  line-height: 0.5rem;
  margin: 0.16rem 0m;
  margin-bottom: 0.69rem;
}
.qrmsg2 span {
  font-weight: bold;
}
.qrmsg {
  font-size: 0.4rem;
  line-height: 0.5rem;
  text-align: center;
  font-weight: bold;
}
.qrmsg span {
  color: #f55613;
}
.im1 {
  text-align: center;
  margin-bottom: 1.4rem;
}
.im1 img {
  width: 4.82rem;
}
.topmsgclass span {
  color: #f55613;
}
.topmsgclass {
  font-size: 0.5rem;
  line-height: 0.7rem;
  margin: 1rem 0;
  text-align: center;
  font-weight: bold;
}
.allclass {
  background-color: #ffffff;
}
</style>